import BasePlugin from '../BasePlugin'

export default class GenerateCalendarPlanAutomaticallyCommand extends BasePlugin {
  async execute () {
    let investmentRequestId = this.context.getModel().id

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentRequest/GenerateCalendarPlanAutomaticallyCommand`,
      { 'investmentRequestId': investmentRequestId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      let gantt = this.context.getDashboardComponents()['component_3d08c068-20f9-4b94-9ef9-e6fe5a7048ba'][0]
      gantt.loadData().then((r) => { gantt.collapse() })
    })
  }
}
